import { pt, en } from "./forms";

// prettier-ignore
export default {
  pt: {
    hints: {
      device_name: "Nome para identificação do dispositivo",
      device_address: "Informe o endereço deste dispositivo",
      is_hi_device: "Dispositivo&nbsp;fabricado pela HI Tecnologia",
      local_storage_enabled: "Habilita&nbsp;coleta&nbsp;de&nbsp;dados&nbsp;no&nbsp;dispositivo (sem depender de conexão com Portal)",
      local_storage_initial_address: 'Endereço da memória inicial da base de coleta local, definida pelo programa do dispositivo',
      local_storage_version: 'Versão da base de coleta local, definida pelo programa do dispositivo',
    },
    error_labels: pt
  },
  en: {
    hints: {
      device_name: "Name to identify the device",
      device_address: "Type here the device address",
      is_hi_device: "Device&nbsp;manufactured by HI Tecnologia",
      local_storage_enabled: "Enables&nbsp;data&nbsp;collect&nbsp;in&nbsp;the&nbsp;device (without depending on a Portal connection)",
      local_storage_initial_address: 'Initial memory address of the local collect base, defined by the device program',
      local_storage_version: 'Version of the local collect base, defined by the device program',
    },
    error_labels: en
  },
};
