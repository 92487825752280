<template>
  <section>
    <PanelHeaderEquipmentList
      v-bind:title="title"
      v-bind:nav="nav"
      icon="fa fa-dashboard"
      :connector_id="connectorId"
      @tab="tab = $event"
    />
    <div
      class="tab-pane"
      role="tabpanel"
      aria-labelledby=""
      id="edit"
      :class="tab == '#edit' ? 'active' : ''"
    >
      <FormDevice
        v-bind:connector_id="connector_id"
        v-bind:device_id="device_id"
        v-on:titleChanged="onTitleChanged"
        v-on:navChanged="onNavChanged"
        @loading="(state) => this.$emit('loading', state)"
        v-on:noData="$emit('noData')"
        :key="formDeviceKey"
        @updateKey="(key) => (formDeviceKey = key || Date.now())"
      />
    </div>
    <div
      class="tab-pane tab-pane-list"
      role="tabpanel"
      aria-labelledby=""
      v-if="deviceId"
      id="list"
      :class="tab == '#list' ? 'active' : ''"
    >
      <DashboardEditDataPicker
        v-if="device_id"
        :key="importCounter.data"
        :connector_id="connector_id"
        :device_id="device_id"
        :editable="true"
        :header="false"
        @resource_imported="resourceImported"
      />
    </div>
  </section>
</template>

<script>
import PanelHeaderEquipmentList from "@/components/panel-header-equipment-list.vue";
import FormDevice from "@/components/registration/form-device.vue";
import DashboardEditDataPicker from "@/components/registration/dashboard-edit-data-picker.vue";
export default {
  name: "DashboardEditDeviceForm",
  components: {
    FormDevice,
    DashboardEditDataPicker,
    PanelHeaderEquipmentList
  },
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    },
    device_id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      title: "loading",
      tab: "",
      nav: null,
      showNextLevel: false,
      formDeviceKey: this.device_id,
      importCounter: {
        device: 0,
        data: 0,
        alarm: 0
      }
    };
  },
  computed: {
    connectorId() {
      return parseInt(
        this.connector_id || this.$route.params.connector_id || 0
      );
    },
    deviceId() {
      return parseInt(this.device_id || this.$route.params.device_id || 0);
    }
  },
  methods: {
    onTitleChanged(title) {
      // this.$emit("titleChanged", this.$tc("device") + ": " + title);
      this.title = this.$tc("device") + ": " + title;
    },
    onNavChanged(nav) {
      if (this.connectorId && this.deviceId) {
        nav = nav || {};
        nav.showTabLabels = true;
        nav.tabs = {
          items: [
            {
              id: "list",
              icon: "glyphicon glyphicon-stats",
              title: "data_list",
              label: this.$tc("tab_label.data", 2)
            },
            {
              id: "edit",
              icon: "portal-icon device[solid]",
              title: "edit",
              label: this.$tc("tab_label.device", 1)
            }
          ]
        };
      }
      // this.$emit("navChanged", nav);
      this.$set(this, "nav", nav);
    },
    resourceImported(entity) {
      this.importCounter[entity] += 1;
    }
  },
  beforeCreate() {
    this.$emit("rule", "EstacaoCadastro");
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 0px 0 30px 0;
  padding: 0px 10px 50px 10px;
}

section > .tab-pane-list {
  padding: 0;
}

.bottom-panel {
  margin-top: 30px;
  padding: 10px;
}
.bottom-panel > .box-header {
  font-size: 16pt;
  margin-left: -10px;
}
</style>
